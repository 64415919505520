import React from 'react';
import styles from './Timetable.styles';
import { css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Link } from 'gatsby';

const Timetable = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(styles.chooseTimeLabel)}>WHICH DAY?</div>
      <div className={css(styles.weekRow)}>
        {props.timetable.map(((day, dayIndex) => (
          <div key={day.id} className={css(styles.dayContainer)}>
            <div className={css(styles.dayLabelContainer, props.chosenDay === dayIndex && styles.chosenDayLabelContainer)}
                 onClick={() => props.toggleDay(dayIndex)}>
              <div className={css(styles.dayNameLabel)}>{day.name}</div>
              <h3 className={css(styles.dayDayLabel)}>{day.day}</h3>
              <p className={css(styles.dayDateLabel)}>{day.date}</p>
            </div>
          </div>
        )))}
      </div>

      {props.timetable[props.chosenDay] && <div>
        <div className={css(styles.chooseTimeLabel)}>CHOOSE A TIME</div>
        <div className={css(styles.timesRow)}>
          {props.timetable[props.chosenDay].slots.map((slot, index) => (
            <div onClick={() => props.toggleTime(index)} className={css(styles.time, slot.numberAvailable === 1 && styles.redTimeslot, !slot.available && styles.unavailableTime, props.chosenTime === index && styles.chosenTime)} key={index}>
              {props.chosenTime !== index && <div className={css(styles.slotTime)}>{slot.time}</div>}
              {props.chosenTime !== index && <div className={css(styles.numberAvailable)}>{slot.numberAvailable} available</div>}
              {props.chosenTime === index && <CircularProgress size={18} className={css(styles.loadingSpinner)} />}
            </div>
          ))}
        </div>
      </div>}

      <div className={css(styles.bookADifferentTimeContainer)}>
        <Link to={'/book/arrival'}>Book for a different time?</Link>
      </div>

    </div>
  </div>
);

export default Timetable;
