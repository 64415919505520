import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    minHeight: '40vh',
    paddingTop: 16,
    [QUERIES.mobile]: {
      paddingTop: 16
    }
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: 16
  },
  timesRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  time: {
    flex: 1,
    minWidth: 200,
    textAlign: 'center',
    margin: 4,
    padding: 12,
    borderRadius: 4,
    position: 'relative',
    border: '2px solid whitesmoke',
    ':hover': {
      border: '2px solid ' + COLORS.kgGreen,
      cursor: 'pointer'
    }
  },
  redTimeslot: {
    border: '2px solid ' + COLORS.hotPink,
  },
  loadingSpinner: {
    marginTop: 13,
    color: COLORS.white
  },
  unavailableTime: {
    opacity: 0.2,
    border: '2px solid whitesmoke',
    ':hover': {
      border: '2px solid whitesmoke' // no change
    }
  },
  chosenTime: {
    border: '2px solid ' + COLORS.kgGreen,
    background: COLORS.kgGreen
  },
  chooseTimeLabel: {
    color: COLORS.lightBlack,
    fontSize: 12,
    padding: 16
  },
  dayContainer: {
    paddingBottom: 24,
    // flex: 1,
    marginRight: 4,
    marginLeft: 4,
    width: 164,
    minWidth: 164
  },
  dayNameLabel: {
    height: 32,
    color: COLORS.offBlack
  },
  dayDayLabel: {
    color: COLORS.offBlack,
    fontWeight: 500,
    margin: 0,
    paddingBottom: 6
  },
  dayDateLabel: {
    color: COLORS.offBlack,
    fontWeight: 500,
    margin: 0,
    fontSize: 14,
    paddingLeft: 4
  },
  dayLabelContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: 16,
    borderRadius: 4,
    border: '2px solid whitesmoke',
    ':hover': {
      border: '2px solid ' + COLORS.kgGreen,
      cursor: 'pointer'
    }
  },
  chosenDayLabelContainer: {
    background: COLORS.kgGreen,
    border: '2px solid ' + COLORS.kgGreen
  },
  weekRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll'
  },
  slotTime: {
    // ...
  },
  numberAvailable: {
    opacity: 0.35,
    fontSize: 12,
    paddingTop: 8
  },
  bookADifferentTimeContainer: {
    textAlign: 'right',
    paddingTop: 64,
    paddingRight: 16,
    color: COLORS.kgGreen
  }
});

export default styles;
