const times = [
  {
    time: '7:00 AM',
    available: true
  },
  {
    time: '8:00 AM',
    available: true
  },
  {
    time: '9:00 AM',
    available: true
  },
  {
    time: '10:00 AM',
    available: true
  },
  {
    time: '12:00 PM',
    available: true
  },
  {
    time: '13:00 PM',
    available: true
  },
  {
    time: '14:00 PM',
    available: true
  },
  {
    time: '15:00 PM',
    available: true
  },
  {
    time: '16:00 PM',
    available: true
  },
  {
    time: '17:00 PM',
    available: true
  },
  {
    time: '18:00 PM',
    available: true
  },
  {
    time: '19:00 PM',
    available: true
  },
  {
    time: '20:00 PM',
    available: true
  },
  {
    time: '21:00 PM',
    available: true
  },
  {
    time: '22:00 PM',
    available: true
  }
];

export const TIMETABLE = [
  {
    id: 0,
    name: 'Today',
    date: null,
    slots: JSON.parse(JSON.stringify(times)) // Deep copy
  },
  {
    id: 1,
    name: 'Tomorrow',
    date: null,
    slots: JSON.parse(JSON.stringify(times))
  },
  {
    id: 2,
    name: null,
    date: null,
    slots: JSON.parse(JSON.stringify(times))
  },
  {
    id: 3,
    name: null,
    date: null,
    slots: JSON.parse(JSON.stringify(times))
  },
  {
    id: 4,
    name: null,
    date: null,
    slots: JSON.parse(JSON.stringify(times))
  },
  {
    id: 5,
    name: null,
    date: null,
    slots: JSON.parse(JSON.stringify(times))
  },
  {
    id: 6,
    name: null,
    date: null,
    slots: JSON.parse(JSON.stringify(times))
  }
];
