import React from 'react';
import TimetableContainer from '../containers/reserve/Timetable/TimetableContainer';
import DownloadFree from '../containers/sections/DownloadFree/DownloadFree';
import Layout from '../containers/gatsby/layout';
import COLORS from '../styles/colors';

const ReservePage = (props) => (
  <Layout location={props.location}>
    <h1 style={{paddingTop: 32, textAlign: 'center', color: COLORS.offBlack}}>Select a day</h1>
    <TimetableContainer/>
    <DownloadFree />
  </Layout>
);

export default ReservePage;
