import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import Timetable from './Timetable';
import { TIMETABLE } from '../../../library/timetable';
import { RESERVE_BOOKING_MODAL } from '../../../library/modals';
import { setCurrentModal } from '../../modals/Modal/ModalActions';
import { setArrivalFromTimetable } from './TimetableActions';
import ReactGA from 'react-ga';

var moment = require('moment');

class TimetableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timetable: [],
      chosenDay: null
    };

    this.activeTimeout = null
  }

  componentDidMount() {
    ReactGA.event({
      category: 'Reservation',
      action: 'Timetable opened',
      label: 'Timetable page opened'
    });

    window.scrollTo(0, 0);
    const smartTimetable = Object.assign([], TIMETABLE);

    smartTimetable.forEach((day, i) => {
      day.pickupDate = moment().add(i, 'day').format('YYYY-MM-DD'); // Formatted for web booking, todo: 'HH:00' for time ...
      day.day = moment().add(i, 'day').format('dddd');
      day.date = moment().add(i, 'day').format('Do MMMM'); // Add date to each day of the week after today

      let randomlyUnavailableTimes = Array.from({length: 10}, () => Math.floor(Math.random() * 15)); // Creates array of 10 random numbers, e.g. [0,12,3,4,4,4...

      day.slots.forEach((slot, slotIndex) => {
        slot.numberAvailable = Math.floor(Math.random() * 4) + 1;

        if (randomlyUnavailableTimes.indexOf(slotIndex) > -1) {
          slot.available = false; // Make unavailable
          slot.numberAvailable = 0;
        } else {
          slot.available = true;
        }
      })
    });

    smartTimetable[0].slots.forEach(slot => {
      let timeNumber = parseInt(slot.time.substr(0, slot.time.indexOf(':')));
      let validTime = parseInt(moment().add(2, 'hour').format('H'));
      slot.available = true; // revert what was done previously to randomly make some unavailable
      slot.numberAvailable = slot.numberAvailable === 0? 1 : slot.numberAvailable; // Number must be at least 1 if available
      if (timeNumber < validTime) {
        slot.numberAvailable = 0;
        slot.available = false;
      }
    });

    // smartTimetable[0].slots[0].available = false; what was this ?

    this.setState({
      timetable: smartTimetable
    }, () => {
      // todo: setFakeTimetable in store?
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.currentModal && this.props.currentModal) { // User closed modal
      this.setState({
        chosenTime: null
      })
    }
  }

  toggleDay = (i) => {
    this.activeTimeout && clearTimeout(this.activeTimeout);
    if (this.state.chosenDay === i) {
      ReactGA.event({
        category: 'Reservation',
        action: 'Day removed',
        label: this.state.timetable[i].day
      });
      this.setState({
        chosenDay: null,
        chosenTime: null
      })
    } else {
      ReactGA.event({
        category: 'Reservation',
        action: 'Day chosen',
        label: this.state.timetable[i].day
      });
      this.setState({
        chosenDay: i,
        chosenTime: null
      })
    }
  };

  toggleTime = (i) => {
    this.activeTimeout && clearTimeout(this.activeTimeout);
    if (this.state.timetable[this.state.chosenDay].slots[i].available) {
      if (this.state.chosenTime === i) {
        ReactGA.event({
          category: 'Reservation',
          action: 'Time removed',
          label: this.state.timetable[this.state.chosenDay].slots[i].time
        });
        this.setState({
          chosenTime: null
        })
      } else {
        ReactGA.event({
          category: 'Reservation',
          action: 'Time chosen',
          label: this.state.timetable[this.state.chosenDay].slots[i].time
        });
        this.handleDayAndTimeChosen(i)
      }
    } else {
      ReactGA.event({
        category: 'Reservation',
        action: 'Unavailable time selected',
        label: this.state.timetable[this.state.chosenDay].slots[i].time
      });
      alert('Time unavailable')
    }
  };

  handleDayAndTimeChosen(i) { // Need in format: YYYY-MM-DD HH:mm
    this.setState({
      chosenTime: i
    }, () => {

      const pickupDate = this.state.timetable[this.state.chosenDay].pickupDate;
      const time = this.state.timetable[this.state.chosenDay].slots[this.state.chosenTime].time.replace(' PM', '').replace(' AM', '');
      const dateAndTime = pickupDate + ' ' + time;
      const formattedDateAndTime = moment(dateAndTime).format('YYYY-MM-DD HH:mm');

      ReactGA.event({
        category: 'Reservation',
        action: 'Day and time chosen',
        label: formattedDateAndTime
      });

      this.props.setArrivalFromTimetable(formattedDateAndTime);

      this.activeTimeout = setTimeout(() => {
        this.props.openModal(RESERVE_BOOKING_MODAL)
      }, 1500)
    })
  }

  render() {
    return (
      <Timetable
        {...this.props}
        timetable={this.state.timetable}
        chosenDay={this.state.chosenDay}
        chosenTime={this.state.chosenTime}
        toggleDay={this.toggleDay}
        toggleTime={this.toggleTime}
      />
    )
  }

  componentWillUnmount() {
    this.activeTimeout && clearTimeout(this.activeTimeout)
  }
}

function mapStateToProps(state) {
  return {
    booking: state.booking,
    currentModal: state.currentModal
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setArrivalFromTimetable: (dateAndTime) => dispatch(setArrivalFromTimetable(dateAndTime)),
    openModal: (modal) => dispatch(setCurrentModal(modal))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimetableContainer);

